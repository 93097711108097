import React, { useState, useMemo} from "react";
import "./MutualFunds.css";
const MutualFundTable = ({ mutualFunds }) => {
//   
const latestDate = Object.keys(mutualFunds).sort().reverse()[0];
const latestData = mutualFunds[latestDate];

  // State to keep track of sort order
  const [sortOrder, setSortOrder] = useState("highest"); // Default to sorting from highest to lowest
  // State to toggle dropdown visibility
  const [showDropdown, setShowDropdown] = useState(false);

  // Function to handle sort order change
  const handleSortChange = (order) => {
    setSortOrder(order);
    setShowDropdown(false); // Hide dropdown after selection
  };

  // Function to sort data by percentage based on sortOrder
  const sortedData = useMemo(() => {
    if (latestData) {
      return [...latestData].sort((a, b) => {
        const diff = parseFloat(a.percentage) - parseFloat(b.percentage);
        return sortOrder === "highest" ? -diff : diff;
      });
    }
    return [];
  }, [latestData, sortOrder]);

  return latestData ? (
    <div className="mutual-funds-table">
      <div className="sort-dropdown" style={{paddingBottom:"10px"}}>
        <div>Sort by</div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          class="bi bi-funnel"
          viewBox="0 0 16 16"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z" />
        </svg>
        {/* Conditional rendering of dropdown */}
        {showDropdown && (
          <div className="sort-options">
            <div
              className="sort-option"
              onClick={() => handleSortChange("highest")}
            >
              Highest to Lowest AUM
            </div>
            <div
              className="sort-option"
              onClick={() => handleSortChange("lowest")}
            >
              Lowest to Highest AUM
            </div>
          </div>
        )}
      </div>

      <table className="table table-striped">
        <thead>
          <tr>
            <th style={{borderRadius: '10px 0 0 0 '}}>MF name</th>
            <th>Instrument</th>
            <th style={{borderRadius: '0 10px 0 0 '}}>AUM</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map(
            (mf, index) =>
              mf.instrument !== "-" && (
                <tr key={index}>
                  <td >{mf.mfName}</td>
                  <td>{mf.instrument}</td>
                  <td >{mf.percentage}</td>
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  ) : null;
};

export default MutualFundTable;
