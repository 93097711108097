import { useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import { useLocation } from 'react-router-dom';

import Navbar from "../Component/LandingV2/Navbar";
import MiniFooter from "../Component/LandingV2/MiniFooter";
import PriceCard from "../Component/Trending/PriceCard";
import TrendingEventCard from "../Component/Trending/TrendingEventCard";
import TickerFilter from "../Component/Trending/TickerFilter";

import "./TrendingPage.scss";

import { AlertData, AlertsJson, QuotesJson } from "../types/alerts.json";
import { Link } from "react-router-dom";


const highlightBoxEnum = {
  PriceChange: 0,
  OpenPrices: 1,
  LastTradedPrice: 2,
  StockVolume: 3,
  MomentumChange: 4,
  AverageTrueRange: 5,
  AverageDirectionalIndex: 6,
  VolumeWeightedAveragePrice: 7,
  HotEvents: 8,
  Quotes: 9,
  NewsCount: 10,
  NewsStories: 11,
  FilterTicker: 12,
};
// useState
const infoBoxText = {
  [highlightBoxEnum.PriceChange]:
    " It indicates the percentage increase 📈 or decrease 📉 in the stock price relative to the previous day's closing price. 📊",
  [highlightBoxEnum.OpenPrices]:
    "New Value displayed in green indicates highest ⬆️ opening price over the last 15days with corresponding second highest Old value similarly value in red indicates lowest ⬇️ opening price over the same period with corresponding second lowest Old value.",
  [highlightBoxEnum.LastTradedPrice]:
    "New Value displayed in green indicates that last traded price is highest ⬆️ as compared to day's close over the last 15 days with corresponding second highest Old value. Similarly value in red indicates that the price is lowest ⬇️ over the same period with corresponding second lowest Old value.",
  [highlightBoxEnum.HotEvents]:
    "Provides a summary for each company’s most significant events. 📚",
  [highlightBoxEnum.NewsCount]:
    "This tab provides insights into a company by showcasing the number of news articles published in the last 24 hours and its weekly news average. 🗞️",
  [highlightBoxEnum.NewsStories]: `The "Top Stories" section highlights significant news where companies are prominently featured. Articles qualify as a top story if the corresponding company is mentioned or referenced in more than five sentences.`,
  [highlightBoxEnum.FilterTicker]:
    "Select a Ticker name of your choice to receive filtered results 🔎",
  [highlightBoxEnum.StockVolume]:
    "Stock volume alerts are based on minute-by-minute comparisons with the last 7 days. A high volume alert is triggered if the current volume exceeds the 7-day high for that exact minute, and vice versa for low volume.",
  [highlightBoxEnum.MomentumChange]:
    "Momentum-based alerts rely on three indicators: RSI, MFI, and Williams %R. If all three indicators simultaneously show the stock as oversold or overbought, an alert is generated to signal potential price movement in the opposite direction.",
  [highlightBoxEnum.AverageDirectionalIndex]:
    "ADX (Average Directional Index) measures trend strength. If ADX exceeds 40, it indicates a strong trend; below 15, it signals a weak trend, based on a 14-day comparison.",
  [highlightBoxEnum.AverageTrueRange]:
    "ATR (Average True Range) measures volatility. If the current ATR exceeds the 14-day max, a high volatility alert is triggered; if below the 14-day min, a low volatility alert is generated.",
  [highlightBoxEnum.VolumeWeightedAveragePrice]:
    "VWAP alerts are based on a 14-day comparison. If the current day's VWAP exceeds the 14-day maximum, an uptrend alert is generated, and if it's below the 14-day minimum, a downtrend alert is triggered.",
  [highlightBoxEnum.Quotes]:
    "This section provides a summary of the company's stock price, market cap, and trading volume.",
};

const Trending = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state.ticker) {
      setTickerFilter(location.state.ticker);
    }
  }, [location.state]);

  const [quotes, setQuotes] = useState<QuotesJson>([]);
  const [data, setData] = useState<AlertData | null>(null);
  const [tickerFilter, setTickerFilter] = useState<string | null>(null);
  const [showInfoBox, setShowInfoBox] = useState(false);
  const [highlightBox, sethighlightBox] = useState(
    highlightBoxEnum.PriceChange
  );
  // const cardRef = useRef<HTMLDivElement>(null);
  const infoBoxRefList = useRef<(HTMLDivElement | null)[]>([]);

  const setInfoBoxRef = (index: number) => (ref: HTMLDivElement | null) => {
    infoBoxRefList.current[index] = ref;
  };

  const toggleInfoBox = () => {
    setShowInfoBox((prev) => !prev); // Show the info-box
    sethighlightBox(highlightBoxEnum.PriceChange); // Reset the highlight box
  };

  useEffect(() => {
    if (showInfoBox && infoBoxRefList.current) {
      const cardRef = infoBoxRefList.current[highlightBox];
      if (!cardRef) {
        console.log("Could not get bounding ref");
        return;
      }
      const cardRect = cardRef.getBoundingClientRect();
      const infoBox = document.getElementById("infoBox");
      if (infoBox) {
        const infoBoxWidth = infoBox.offsetWidth;
        const viewportWidth = window.innerWidth;
        let leftPosition = cardRect.right + window.scrollX + 20;
        let topPosition = cardRect.top + window.scrollY;
        if (viewportWidth < 400) {
          if (leftPosition + infoBoxWidth > viewportWidth) {
            leftPosition = viewportWidth - infoBoxWidth - 40;
          }
          infoBox.style.width = "135px";
          infoBox.style.fontSize = "12px";
          topPosition = cardRect.bottom + window.scrollY + 5;
        } else if (viewportWidth < 768) {
          if (leftPosition + infoBoxWidth > viewportWidth) {
            leftPosition = viewportWidth - infoBoxWidth - 40;
          }
          infoBox.style.width = "135px";
          infoBox.style.fontSize = "12px";
          topPosition = cardRect.bottom + window.scrollY + 5;
        } else {
          if (leftPosition + infoBoxWidth > viewportWidth) {
            leftPosition = cardRect.left - infoBoxWidth - 5;
          }
          infoBox.style.width = "";
          infoBox.style.fontSize = "";
        }
        infoBox.style.top = `${topPosition}px`;
        infoBox.style.left = `${leftPosition}px`;
        infoBox.style.zIndex = "25";
      }
    }
  }, [showInfoBox, highlightBox]);

  const fetchData = async () => {
    try {
      const response = await fetch(
        (process.env.REACT_APP_COMPANY_DOC || "") + "/Alerts/alerts.json"
      );
      const data: AlertsJson = await response.json();

      const quotes_response = await fetch(
        (process.env.REACT_APP_COMPANY_DOC || "") + "/Alerts/quotes_alerts.json"
      );
      const quotesData: QuotesJson = await quotes_response.json();
      setQuotes(quotesData);

      const latestDate = Object.keys(data).sort().reverse()[0];

      setData({ ...data[latestDate], date: latestDate });
      
    } catch (err) {
      console.error(err);
    }
  };

  // const fetchData = async () => {
  //   try {
  //     // Use the imported JSON data directly
  //     const data: AlertsJson = response;
  //     console.log("---------------data-----------------", data);

  //     // Find latest date
  //     const latestDate = Object.keys(data).sort().reverse()[0];

  //     setData({ ...data[latestDate], date: latestDate });
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  useEffect(() => {
    fetchData();
  }, []);

  // Loading state
  if (!data) {
    return (
      <>
        <Navbar />
        <div className="loading">Loading...</div>
      </>
    );
  }

  // Create list of open highest difference
  // List of highest
  const combinedOpenValues = Object.keys(
    data.price_change_alerts.alerts_open_highest
  ).map((key) => {
    return {
      ticker: key,
      ...data.price_change_alerts.alerts_open_highest[key],
      minmax: data.price_change_alerts.alerts_open_highest[key].max_open || 0,
    };
  });
  // Concat lowest
  combinedOpenValues.push(
    ...Object.keys(data.price_change_alerts.alerts_open_lowest).map((key) => {
      return {
        ticker: key,
        ...data.price_change_alerts.alerts_open_lowest[key],
        minmax: data.price_change_alerts.alerts_open_lowest[key].min_open || 0,
      };
    })
  );

  // Sort by difference between live day and minmax
  combinedOpenValues
    .sort((a, b) => {
      return (
        Math.abs(a.live_day_open - a.minmax) -
        Math.abs(b.live_day_open - b.minmax)
      );
    })
    .reverse();

  // console.log(combinedOpenValues);

  // Create list of close highest difference
  // List of highest
  const combinedCloseValues = Object.keys(
    data.price_change_alerts.alerts_close_highest
  ).map((key) => {
    return {
      ticker: key,
      ...data.price_change_alerts.alerts_close_highest[key],
      minmax: data.price_change_alerts.alerts_close_highest[key].max_close || 0,
    };
  });
  // Concat lowest
  combinedCloseValues.push(
    ...Object.keys(data.price_change_alerts.alerts_close_lowest).map((key) => {
      return {
        ticker: key,
        ...data.price_change_alerts.alerts_close_lowest[key],
        minmax:
          data.price_change_alerts.alerts_close_lowest[key].min_close || 0,
      };
    })
  );

  const combined_Adx_values = data.ADX_change
    ? Object.keys(data.ADX_change.strong_trend).map((key) => {
        return {
          ticker: key,
          trendType: "strong trend", // Add the trend type
          ...data.ADX_change!.strong_trend[key],
        };
      })
    : [];

  // Concat weak trend stocks if ADX_change is available
  if (data.ADX_change) {
    combined_Adx_values.push(
      ...Object.keys(data.ADX_change.weak_trend).map((key) => {
        return {
          ticker: key,
          trendType: "weak trend", // Add the trend type
          ...data.ADX_change!.weak_trend[key],
        };
      })
    );
  }

  // ATR_change
  const combined_Atr_values = data.ATR_change?.high_volatility
    ? Object.keys(data.ATR_change.high_volatility).map((key) => ({
        ticker: key,
        volatilityType: "high_volatility",
        ...data.ATR_change!.high_volatility[key],
      }))
    : [];

  if (data.ATR_change?.low_volatility) {
    combined_Atr_values.push(
      ...Object.keys(data.ATR_change.low_volatility).map((key) => ({
        ticker: key,
        volatilityType: "low_volatility",
        ...data.ATR_change!.low_volatility[key],
      }))
    );
  }

  // StockVolume_change
  const combined_volume_change = data.StockVolume_change?.Stock_volume_high
    ? Object.keys(data.StockVolume_change.Stock_volume_high).map((key) => {
        return {
          ticker: key,
          volume_type: "high_volume",
          ...data.StockVolume_change!.Stock_volume_high[key],
        };
      })
    : [];

  if (data.StockVolume_change?.Stock_volume_low) {
    combined_volume_change.push(
      ...Object.keys(data.StockVolume_change.Stock_volume_low).map((key) => {
        return {
          ticker: key,
          volume_type: "low_volume",
          ...data.StockVolume_change!.Stock_volume_low[key],
        };
      })
    );
  }

  // VWAP_change
  const combined_Vwap = data.VWAP_change?.Uptrend
    ? Object.keys(data.VWAP_change.Uptrend).map((key) => {
        return {
          ticker: key,
          trend_type: "uptrend",
          ...data.VWAP_change!.Uptrend[key],
        };
      })
    : [];

  if (data.VWAP_change?.Downtrend) {
    combined_Vwap.push(
      ...Object.keys(data.VWAP_change.Downtrend).map((key) => {
        return {
          ticker: key,
          trend_type: "downtrend",
          ...data.VWAP_change!.Downtrend[key],
        };
      })
    );
  }

  // Momentum_change
  const combined_momentum_change = data.Momentum_change?.Overbought
    ? Object.keys(data.Momentum_change.Overbought).map((key) => {
        return {
          ticker: key,
          bought_type: "overbought",
          ...data.Momentum_change!.Overbought[key],
        };
      })
    : [];

  if (data.Momentum_change?.Oversold) {
    combined_momentum_change.push(
      ...Object.keys(data.Momentum_change.Oversold).map((key) => {
        return {
          ticker: key,
          bought_type: "oversold",
          ...data.Momentum_change!.Oversold[key],
        };
      })
    );
  }

  // Sort by difference
  combinedCloseValues
    .sort((a, b) => {
      return (
        Math.abs(a.live_day_close - a.minmax) -
        Math.abs(b.live_day_close - b.minmax)
      );
    })
    .reverse();

  // List of all unique tickers for filter
  // Sort alphabetically
  const tickerList = [
    ...new Set([
      ...Object.keys(data.price_change_alerts.top_n_price_change.stock_symbol),
      ...Object.values(data.price_change_alerts.alerts_open_highest).map(
        (ele) => ele.ticker_symbol
      ),
      ...Object.values(data.price_change_alerts.alerts_open_lowest).map(
        (ele) => ele.ticker_symbol
      ),
      ...Object.values(data.price_change_alerts.alerts_close_highest).map(
        (ele) => ele.ticker_symbol
      ),
      ...Object.values(data.price_change_alerts.alerts_close_lowest).map(
        (ele) => ele.ticker_symbol
      ),
    ]),
  ].sort();

  // console.log(tickerFilter, tickerList);

  return (
    <>
      <Navbar />
      <section className="trending-section">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" className="link" onClick={toggleInfoBox}>
          What's this?
        </a>
        {showInfoBox && (
          <>
            <div id="infoBox" className="info-box">
              {/* Your info-box content here */}
              <div className="info-box-content">
                <p>{infoBoxText[highlightBox]}</p>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    width={18}
                    height={18}
                    onClick={() => setShowInfoBox(false)}
                  >
                    <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
                  </svg>
                </div>
              </div>
              <div className="info-box-bottom">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  fill="#fe5f55"
                  fillOpacity={0.8}
                  width={10}
                  height="1.5rem"
                  onClick={() =>
                    sethighlightBox((prev) => (prev - 1 < 0 ? 0 : prev - 1))
                  }
                >
                  <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
                </svg>
                <span>
                  {highlightBox + 1}/{Object.keys(highlightBoxEnum).length}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  fill="#fe5f55"
                  fillOpacity={0.8}
                  width={10}
                  height="1.5rem"
                  onClick={() =>
                    sethighlightBox((prev) =>
                      prev + 1 > Object.keys(highlightBoxEnum).length
                        ? 0
                        : prev + 1
                    )
                  }
                >
                  <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                </svg>
              </div>
            </div>
            <div
              className="modal-backdrop"
              // onClick={() => setShowInfoBox(false)}
            ></div>
          </>
        )}
        <div className="grey-box">
          <div className="three-pane-split">
            <div className="price-pane">
              {/* Ticker filter Mobile view only */}
              <div className="only-mobile">
                <TickerFilter
                  selectedTicker={tickerFilter ?? undefined}
                  allTickers={tickerList}
                  onChange={(value) => {
                    setTickerFilter(value);
                  }}
                />
              </div>
              {/* Price Change card */}
              <div
                className="outer-div-cont"
                style={{ display: "flex", gap: "0px", minWidth: "max-content" }}
              >
                <div className="coloumn-1-trending">
                  <PriceCard
                    setInfoBoxRef={setInfoBoxRef(highlightBoxEnum.PriceChange)}
                    toggleInfoBox={toggleInfoBox}
                    highlightBox={
                      showInfoBox &&
                      highlightBox === highlightBoxEnum.PriceChange
                        ? true
                        : false
                    }
                    title={
                      <>
                        <span className="price-change-title">Price Change</span>
                        <Tooltip
                          anchorSelect=".price-change-title"
                          place="top"
                          style={{
                            width: "80%",
                            fontSize: "0.8rem",
                            fontWeight: 400,
                          }}
                        >
                          Percent of change from previous day <br />
                          Sorted by percent change
                        </Tooltip>
                      </>
                    }
                    headers={[
                      "Stock Ticker",
                      <>
                        <div className="price-change-value-header">
                          Value (% Change)
                        </div>
                        <Tooltip
                          anchorSelect=".price-change-value-header"
                          place="top"
                        >
                          Percent of change from previous day
                        </Tooltip>
                      </>,
                    ]}
                    values={Object.keys(
                      data.price_change_alerts.top_n_price_change.stock_symbol
                    )
                      // Filter by ticker
                      .filter((key) => {
                        if (!tickerFilter) return true;
                        return key === tickerFilter;
                      })
                      .map((key, i) => {
                        const isRed =
                          data.price_change_alerts.top_n_price_change
                            .stock_symbol[key] < 0;
                        return [
                          <div
                            className="ticker-name-trending"
                            onClick={() => {
                              const ticker = key;
                              if (ticker) {
                                window.localStorage.setItem("selectedTab", "0");
                                window.localStorage.setItem("tickerId", ticker);
                                window.location.href = `/detail`;
                              }
                            }}
                          >
                            {key}
                          </div>,
                          <div className="int-value">
                            <div
                              className={
                                isRed
                                  ? "text-red-price-change"
                                  : "text-green-price-change"
                              }
                              key={i}
                            >
                              {data.price_change_alerts.top_n_price_change.stock_symbol[
                                key
                              ]
                                .toPrecision(3)
                                .toString()}
                              {/* {isRed ? <RedDownArrow /> : <GreenUpArrow />} */}
                            </div>
                          </div>,
                        ];
                      })
                      .flat()}
                  />
                  {/* Open Price Card */}
                  <PriceCard
                    toggleInfoBox={toggleInfoBox}
                    setInfoBoxRef={setInfoBoxRef(highlightBoxEnum.OpenPrices)}
                    highlightBox={
                      showInfoBox &&
                      highlightBox === highlightBoxEnum.OpenPrices
                        ? true
                        : false
                    }
                    title={
                      <>
                        <span className="open-price-title">Open Prices</span>
                        <Tooltip
                          anchorSelect=".open-price-title"
                          place="top"
                          style={{
                            width: "80%",
                            fontSize: "0.8rem",
                            fontWeight: 400,
                          }}
                        >
                          Identifies the highest and lowest open prices in the
                          last 15 days
                        </Tooltip>
                      </>
                    }
                    headers={[
                      "Stock Ticker",
                      <>
                        <div className="open-price-value-header">
                          New Value*
                        </div>
                        <Tooltip
                          anchorSelect=".open-price-value-header"
                          place="top"
                        >
                          New value of the stock
                        </Tooltip>
                      </>,
                      <>
                        <div className="open-price-minmax-header">
                          Old Value
                        </div>
                        <Tooltip
                          anchorSelect=".open-price-minmax-header"
                          place="top"
                        >
                          Old value of the stock
                        </Tooltip>
                      </>,
                    ]}
                    values={combinedOpenValues
                      // Filter by ticker
                      .filter((key) => {
                        if (!tickerFilter) return true;
                        return key.ticker_symbol === tickerFilter;
                      })
                      .map((value, i) => {
                        const isRed = value.min_open !== undefined;
                        return [
                          <div
                            className="ticker-name-trending"
                            onClick={() => {
                              const ticker = value.ticker_symbol;
                              if (ticker) {
                                window.localStorage.setItem("selectedTab", "0");
                                window.localStorage.setItem("tickerId", ticker);
                                window.location.href = `/detail`;
                              }
                            }}
                          >
                            <div key={`${i}-ticker`}>
                              {" "}
                              {value.ticker_symbol}{" "}
                            </div>
                          </div>,
                          <div className="int-value">
                            <div
                              className={isRed ? "text-red" : "text-green"}
                              key={`${i}-value`}
                            >
                              {value.live_day_open.toString()}
                            </div>
                          </div>,
                          <div key={`${i}-minmax`}>
                            {" "}
                            {value.minmax.toString()}{" "}
                          </div>,
                        ];
                      })
                      .flat()}
                  />
                  {/* Close Prices Card */}
                  <PriceCard
                    toggleInfoBox={toggleInfoBox}
                    setInfoBoxRef={setInfoBoxRef(
                      highlightBoxEnum.LastTradedPrice
                    )}
                    highlightBox={
                      showInfoBox &&
                      highlightBox === highlightBoxEnum.LastTradedPrice
                        ? true
                        : false
                    }
                    title={
                      <>
                        <span className="close-price-title">Close Prices</span>
                        <Tooltip
                          anchorSelect=".close-price-title"
                          place="top"
                          style={{
                            width: "80%",
                            fontSize: "0.8rem",
                            fontWeight: 400,
                          }}
                        >
                          Identifies the highest and lowest close prices in the
                          last 15 days
                        </Tooltip>
                      </>
                    }
                    headers={[
                      "Stock Ticker",
                      <>
                        <div className="close-price-value-header">
                          New Value*
                        </div>
                        <Tooltip
                          anchorSelect=".close-price-value-header"
                          place="top"
                        >
                          New value of the stock
                        </Tooltip>
                      </>,
                      <>
                        <div className="close-price-minmax-header">
                          Old Value
                        </div>
                        <Tooltip
                          anchorSelect=".close-price-minmax-header"
                          place="top"
                        >
                          Old value of the stock
                        </Tooltip>
                      </>,
                    ]}
                    values={combinedCloseValues
                      // Filter by ticker
                      .filter((key) => {
                        if (!tickerFilter) return true;
                        return key.ticker_symbol === tickerFilter;
                      })
                      .map((value, i) => {
                        const isRed = value.min_close !== undefined;
                        return [
                          <div
                            className="ticker-name-trending"
                            onClick={() => {
                              const ticker = value.ticker_symbol;
                              if (ticker) {
                                window.localStorage.setItem("selectedTab", "0");
                                window.localStorage.setItem("tickerId", ticker);
                                window.location.href = `/detail`;
                              }
                            }}
                          >
                            <div key={`${i}-ticker`}>
                              {" "}
                              {value.ticker_symbol}{" "}
                            </div>
                          </div>,
                          <div className="int-value">
                            <div
                              className={isRed ? "text-red" : "text-green"}
                              key={`${i}-value`}
                            >
                              {value.live_day_close.toString()}
                            </div>
                          </div>,
                          <div key={`${i}-minmax`}>
                            {" "}
                            {value.minmax.toString()}{" "}
                          </div>,
                        ];
                      })
                      .flat()}
                  />

                  <PriceCard
                    toggleInfoBox={toggleInfoBox}
                    setInfoBoxRef={setInfoBoxRef(highlightBoxEnum.StockVolume)}
                    highlightBox={
                      showInfoBox &&
                      highlightBox === highlightBoxEnum.StockVolume
                        ? true
                        : false
                    }
                    title={
                      <>
                        <span className="volume-change-title">
                          Stock Volume
                        </span>
                        <Tooltip
                          anchorSelect=".volume-change-title"
                          place="top"
                          style={{
                            width: "80%",
                            fontSize: "0.8rem",
                            fontWeight: 400,
                          }}
                        >
                          Triggers when current minute's volume exceeds or falls
                          below the 7-day high or low volume for that minute
                        </Tooltip>
                      </>
                    }
                    headers={[
                      "Stock Ticker",
                      <>
                        <div className="volume-value-header">Value*</div>
                        <Tooltip
                          anchorSelect=".volume-value-header"
                          place="top"
                        >
                          value of the stock
                        </Tooltip>
                      </>,
                      <>
                        <div className="volume-minmax-header">Alerts</div>
                        <Tooltip
                          anchorSelect=".volume-minmax-header"
                          place="top"
                        >
                          Volume Alert
                        </Tooltip>
                      </>,
                    ]}
                    values={combined_volume_change
                      // Filter by ticker
                      .filter((key) => {
                        if (!tickerFilter) return true;
                        return key.ticker_symbol === tickerFilter;
                      })
                      .map((value, i) => {
                        const isRed = undefined;
                        return [
                          <div
                            className="ticker-name-trending"
                            onClick={() => {
                              const ticker = value.ticker;
                              if (ticker) {
                                window.localStorage.setItem("selectedTab", "0");
                                window.localStorage.setItem("tickerId", ticker);
                                window.location.href = `/detail`;
                              }
                            }}
                          >
                            <div key={`${i}-ticker`}>
                              {" "}
                              {value.ticker_symbol}{" "}
                            </div>
                          </div>,
                          <div className="int-value">
                            <div
                              className={
                                value.volume_type !== "high_volume"
                                  ? "text-red"
                                  : "text-yellow"
                              }
                              key={`${i}-value`}
                            >
                              {value.Max_volume.toFixed(2)}
                            </div>
                          </div>,
                          <div key={`${i}-minmax`}>
                            {value.volume_type === "high_volume"
                              ? "High"
                              : "Low"}
                          </div>,
                        ];
                      })
                      .flat()}
                  />
                </div>
                {/* column1 ends */}

                {/* column2 starts */}
                <div className="column2">
                  {/* Momentum Change card */}
                  <PriceCard
                    toggleInfoBox={toggleInfoBox}
                    setInfoBoxRef={setInfoBoxRef(
                      highlightBoxEnum.MomentumChange
                    )}
                    highlightBox={
                      showInfoBox &&
                      highlightBox === highlightBoxEnum.MomentumChange
                        ? true
                        : false
                    }
                    title={
                      <>
                        <span className="momentum-change-title">
                          Momentum Change
                        </span>
                        <Tooltip
                          anchorSelect=".momentum-change-title"
                          place="top"
                          style={{
                            width: "80%",
                            fontSize: "0.8rem",
                            fontWeight: 400,
                          }}
                        >
                          Generated when RSI, MFI, and Williams %R
                          simultaneously signal oversold or overbought
                          conditions.
                        </Tooltip>
                      </>
                    }
                    headers={[
                      "Stock Ticker",
                      <>
                        <div className="momentum-price-value-header">
                          Value*
                        </div>
                        <Tooltip anchorSelect=".momentum-price-value-header">
                          value of the stock
                        </Tooltip>
                      </>,
                      <>
                        <div className="momentum-price-minmax-header">
                          Alerts
                        </div>
                        <Tooltip anchorSelect=".momentum-price-minmax-header">
                          Momentum of the stock
                        </Tooltip>
                      </>,
                    ]}
                    values={combined_momentum_change
                      // Filter by ticker
                      .filter((key) => {
                        if (!tickerFilter) return true;
                        return key.ticker === tickerFilter;
                      })
                      .map((value, i) => {
                        const isRed = undefined;
                        return [
                          <div
                            className="ticker-name-trending"
                            onClick={() => {
                              const ticker = value.ticker;
                              if (ticker) {
                                window.localStorage.setItem("selectedTab", "0");
                                window.localStorage.setItem("tickerId", ticker);
                                window.location.href = `/detail`;
                              }
                            }}
                          >
                            <div key={`${i}-ticker`}>
                              {" "}
                              {value.ticker_symbol}{" "}
                            </div>
                          </div>,
                          <div className="int-value">
                            <div
                              className={
                                value.bought_type !== "overbought"
                                  ? "text-red"
                                  : "text-green"
                              }
                            >
                              {value.MFI.toFixed(2)}
                            </div>
                          </div>,
                          <div key={`${i}-minmax`}>{value.Alert}</div>,
                        ];
                      })
                      .flat()}
                  />
                  {/* ATR Card */}
                  <PriceCard
                    toggleInfoBox={toggleInfoBox}
                    setInfoBoxRef={setInfoBoxRef(
                      highlightBoxEnum.AverageTrueRange
                    )}
                    highlightBox={
                      showInfoBox &&
                      highlightBox === highlightBoxEnum.AverageTrueRange
                        ? true
                        : false
                    }
                    title={
                      <>
                        <span className="atr-change-title">ATR</span>
                        <Tooltip
                          anchorSelect=".atr-change-title"
                          place="top"
                          style={{
                            width: "80%",
                            overflowY: "scroll",
                            fontSize: "0.8rem",
                            fontWeight: 400,
                          }}
                        >
                          ATR (Average True Range) measures volatility. If the
                          current ATR exceeds the 14-day max, a high volatility
                          alert is triggered; if below the 14-day min, a low
                          volatility alert is generated.
                        </Tooltip>
                      </>
                    }
                    headers={[
                      "Stock Ticker",
                      <>
                        <div className="atr-price-value-header">Value*</div>
                        <Tooltip anchorSelect=".atr-price-value-header">
                          value of the stock
                        </Tooltip>
                      </>,
                      <>
                        <div className="atr-price-minmax-header">
                          Volatility
                        </div>
                        <Tooltip anchorSelect=".atr-price-minmax-header">
                          Volatility Alert
                        </Tooltip>
                      </>,
                    ]}
                    values={combined_Atr_values
                      // Filter by ticker
                      .filter((key) => {
                        if (!tickerFilter) return true;
                        return key.ticker_symbol === tickerFilter;
                      })
                      .map((value, i) => {
                        return [
                          <div
                            className="ticker-name-trending"
                            onClick={() => {
                              const ticker = value.ticker_symbol;
                              if (ticker) {
                                window.localStorage.setItem("selectedTab", "0");
                                window.localStorage.setItem("tickerId", ticker);
                                window.location.href = "/detail";
                              }
                            }}
                          >
                            <div key={`${i}-ticker`}>
                              {" "}
                              {value.ticker_symbol}{" "}
                            </div>
                          </div>,
                          <div className="int-value">
                            <div
                              className={
                                value.volatilityType !== "high_volatility"
                                  ? "text-red"
                                  : "text-yellow"
                              }
                              key={`${i}-value`}
                            >
                              {value.Last_ATR.toFixed(2)}
                            </div>
                          </div>,
                          <div key={`${i}-minmax`}>
                            {value.volatilityType === "high_volatility"
                              ? "High"
                              : "Low"}
                          </div>,
                        ];
                      })
                      .flat()}
                  />
                  {/* ADX Card */}
                  <PriceCard
                    toggleInfoBox={toggleInfoBox}
                    setInfoBoxRef={setInfoBoxRef(
                      highlightBoxEnum.AverageDirectionalIndex
                    )}
                    highlightBox={
                      showInfoBox &&
                      highlightBox === highlightBoxEnum.AverageDirectionalIndex
                        ? true
                        : false
                    }
                    title={
                      <>
                        <span className="adx-change-title">ADX</span>
                        <Tooltip
                          anchorSelect=".adx-change-title"
                          place="top"
                          style={{
                            width: "80%",
                            fontSize: "0.8rem",
                            fontWeight: 400,
                          }}
                        >
                          ADX (Average Directional Index) measures trend
                          strength. If ADX exceeds 40, it indicates a strong
                          trend; below 15, it signals a weak trend, based on a
                          14-day comparison.
                        </Tooltip>
                      </>
                    }
                    headers={[
                      "Stock Ticker",
                      <>
                        <div className="adx-price-value-header">Value*</div>
                        <Tooltip anchorSelect=".adx-price-value-header">
                          value of the stock
                        </Tooltip>
                      </>,
                      <>
                        <div className="adx-price-minmax-header">Trend</div>
                        <Tooltip anchorSelect=".adx-price-minmax-header">
                          Trend Alert
                        </Tooltip>
                      </>,
                    ]}
                    values={combined_Adx_values
                      // Filter by ticker
                      .filter((key) => {
                        if (!tickerFilter) return true;
                        return key.ticker_symbol === tickerFilter;
                      })
                      .map((value, i) => {
                        return [
                          <div
                            className="ticker-name-trending"
                            onClick={() => {
                              const ticker = value.ticker_symbol;
                              if (ticker) {
                                window.localStorage.setItem("selectedTab", "0");
                                window.localStorage.setItem("tickerId", ticker);
                                window.location.href = `/detail`;
                              }
                            }}
                          >
                            <div key={`${i}-ticker`}>{value.ticker_symbol}</div>
                          </div>,
                          <div className="int-value">
                            <div
                              className={
                                value.trendType !== "strong trend"
                                  ? "text-red"
                                  : "text-green"
                              }
                              key={`${i}-value`}
                            >
                              {value.ADX.toFixed(2)}
                            </div>
                          </div>,
                          <div key={`${i}-minmax`}>
                            {value.trendType === "strong trend"
                              ? "Strong"
                              : "Weak"}
                          </div>,
                        ];
                      })
                      .flat()}
                  />
                  {/* VWAP Card */}
                  <PriceCard
                    toggleInfoBox={toggleInfoBox}
                    setInfoBoxRef={setInfoBoxRef(
                      highlightBoxEnum.VolumeWeightedAveragePrice
                    )}
                    highlightBox={
                      showInfoBox &&
                      highlightBox ===
                        highlightBoxEnum.VolumeWeightedAveragePrice
                        ? true
                        : false
                    }
                    title={
                      <>
                        <span className="vwap-change-title">VWAP</span>
                        <Tooltip
                          anchorSelect=".vwap-change-title"
                          place="top"
                          style={{
                            width: "80%",
                            fontSize: "0.8rem",
                            fontWeight: 400,
                          }}
                        >
                          Signals an uptrend if the current VWAP surpasses the
                          14-day max, and a downtrend if below the 14-day min
                        </Tooltip>
                      </>
                    }
                    headers={[
                      "Stock Ticker",
                      <>
                        <div className="vwap-value-header">Value*</div>
                        <Tooltip anchorSelect=".vwap-value-header" place="top">
                          value of the stock
                        </Tooltip>
                      </>,
                      <>
                        <div className="vwap-minmax-header">Trend</div>
                        <Tooltip anchorSelect=".vwap-minmax-header">
                          Trend of the stock
                        </Tooltip>
                      </>,
                    ]}
                    values={combined_Vwap
                      // Filter by ticker
                      .filter((key) => {
                        if (!tickerFilter) return true;
                        return key.ticker === tickerFilter;
                      })
                      .map((value, i) => {
                        return [
                          <div
                            className="ticker-name-trending"
                            onClick={() => {
                              const ticker = value.ticker;
                              if (ticker) {
                                window.localStorage.setItem("selectedTab", "0");
                                window.localStorage.setItem("tickerId", ticker);
                                window.location.href = `/detail`;
                              }
                            }}
                          >
                            <div key={`${i}-ticker`}>
                              {" "}
                              {value.ticker_symbol}{" "}
                            </div>
                          </div>,
                          <div className="int-value">
                            <div
                              className={
                                value.trend_type !== "uptrend"
                                  ? "text-red"
                                  : "text-green"
                              }
                              key={`${i}-value`}
                            >
                              {value.current_vwap}
                            </div>
                          </div>,
                          <div key={`${i}-minmax`}>
                            {value.trend_type === "uptrend" ? "Up" : "Down"}
                          </div>,
                        ];
                      })
                      .flat()}
                  />
                </div>
                {/* column2 ends */}
              </div>
            </div>

            {/* column3 starts */}
            <div className="trending-wrapper">
            <div className="news-stories-pane">
              {/* Ticker filter Desktop view */}
              <div
                className="only-desktop"
                style={
                  highlightBox === highlightBoxEnum.FilterTicker
                    ? { zIndex: 20 }
                    : {}
                }
                ref={(ref) =>
                  (infoBoxRefList.current[highlightBoxEnum.FilterTicker] = ref)
                }
              >
                <TickerFilter
                  selectedTicker={tickerFilter ?? undefined}
                  allTickers={tickerList}
                  onChange={(value) => {
                    setTickerFilter(value);
                  }}
                />
              </div>
              

              {/* news count ends */}
            </div>
              {/* Hot Events Card */}
              <div
                className="events-pane container-events"
                style={
                  highlightBox === highlightBoxEnum.HotEvents
                    ? { zIndex: 20 }
                    : {}
                }
                ref={(ref) =>
                  (infoBoxRefList.current[highlightBoxEnum.HotEvents] = ref)
                }
              >
                <>
                  <h3 className="heading">Hot Events</h3>
                  <Tooltip anchorSelect=".hot-event-title" place="top">
                    Identifies top news events and gathers related news data to
                    provide a summary for each company's most important event.
                  </Tooltip>
                </>

                <div className="events-position">
                  <div className="events-flex">
                    {(() => {
                      const filteredEvents = Object.keys(
                        data.events_with_summary
                      )
                        // Filter by ticker
                        .filter((key) => {
                          if (!tickerFilter) return true;
                          return (
                            data.events_with_summary[key].stock_symbol ===
                            tickerFilter
                          );
                        });

                      return filteredEvents.length === 0 ? (
                        <div>
                          <div className="no-data">No data available</div>
                        </div>
                      ) : (
                        filteredEvents.map((key, i) => (
                          <TrendingEventCard
                            key={i}
                            title={`${key}`}
                            ticker={data.events_with_summary[key].stock_symbol}
                            events={[
                              {
                                title: data.events_with_summary[key].max_event,
                                subtitle: data.events_with_summary[key].summary,
                              },
                            ]}
                          />
                        ))
                      );
                    })()}
                  </div>
                </div>
              </div>

              {/* new qoutes section start*/}
              <div
                className="events-pane container-events"
                style={
                  highlightBox === highlightBoxEnum.Quotes ? { zIndex: 20 } : {}
                }
                ref={(ref) =>
                  (infoBoxRefList.current[highlightBoxEnum.Quotes] = ref)
                }
              >
                <>
                  <h3 className="heading">Quotes</h3>
                  <Tooltip anchorSelect=".hot-event-title" place="top">
                    Identifies top Quotes and gathers related data to provide a
                    summary and context for each company/person.
                  </Tooltip>
                </>
                <div className="events-position">
                  <div className="events-flex">
                    {(() => {
                      const filteredQuotes = quotes
                        .filter((quote) => {
                          if (!tickerFilter) return true;
                          return quote.ticker_symbol === tickerFilter;
                        })
                        .filter(
                          (quote) =>
                            quote.quote !== "0" && quote.context !== "0"
                        ); // Filter out quotes and contexts with value 0

                      return filteredQuotes.length === 0 ? (
                        <div>
                          <div className="no-data">No data available</div>
                        </div>
                      ) : (
                        filteredQuotes.map((quote, i) => (
                          <TrendingEventCard
                            key={i}
                            title={quote.company_name}
                            ticker={quote.ticker_symbol}
                            events={[
                              {
                                title: `"${quote.quote}"`,
                                subtitle: quote.context,
                              },
                            ]}
                          >
                            {/* Display person's name at the bottom */}
                            <div
                              className="person-name"
                              style={{ marginTop: "10px", fontStyle: "italic" }}
                            >
                              - {quote.Person}
                            </div>
                          </TrendingEventCard>
                        ))
                      );
                    })()}
                  </div>
                </div>
              </div>
               {/* Top Stories Card */}
               <div className="story-news-combo">
            <div
                className="top-stories-card"
                style={
                  highlightBox === highlightBoxEnum.NewsStories
                    ? { zIndex: 20 }
                    : {}
                }
                ref={(ref) =>
                  (infoBoxRefList.current[highlightBoxEnum.NewsStories] = ref)
                }
              >
                <>
                  <h3 className="title top-stories-title">Top Stories</h3>
                  <Tooltip anchorSelect=".top-stories-title" place="top">
                    "Top Stories" features news with the corresponding company
                    mentioned over five times.
                  </Tooltip>
                </>
                <div className="stories-list">
                  {(() => {
                    const filteredStories = Object.keys(
                      data.Top_stories_using_TSA_count
                    )
                      // Filter by ticker
                      .filter((key) => {
                        if (!tickerFilter) return true;
                        return (
                          data.Top_stories_using_TSA_count[key].stock_symbol ===
                          tickerFilter
                        );
                      });

                    return filteredStories.length === 0 ? (
                      <div className="no-data"> NO STORY !</div>
                    ) : (
                      filteredStories.map((key, i) => (
                        <a
                          className="story"
                          key={i}
                          href={data.Top_stories_using_TSA_count[key].link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <h4 className="date-time">
                            {data.Top_stories_using_TSA_count[key].news_date}
                          </h4>
                          <div className="story-title">
                            {data.Top_stories_using_TSA_count[key].title}
                          </div>
                        </a>
                      ))
                    );
                  })()}
                </div>
              </div>
              {/* News Count card starts*/}
              <div
                className="news-count-card"
                style={
                  highlightBox === highlightBoxEnum.NewsCount
                    ? { zIndex: 20 }
                    : {}
                }
                ref={(ref) =>
                  (infoBoxRefList.current[highlightBoxEnum.NewsCount] = ref)
                }
              >
                <>
                  <h3 className="title news-count-title">News Count</h3>
                  <Tooltip anchorSelect=".news-count-title" place="top">
                    Identifies the number of news articles published in the last
                    24 hours and the weekly average.
                  </Tooltip>
                </>

                <div className="news-list">
                  {data?.news_count?.all_companies ? (
                    (() => {
                      const filteredNews = Object.keys(
                        data.news_count.all_companies
                      )
                        ?.sort()
                        // Filter by ticker
                        .filter((key) => {
                          if (!tickerFilter) return true;
                          return (
                            data.news_count.all_companies[key].stock_symbol ===
                            tickerFilter
                          );
                        });

                      return filteredNews.length === 0 ? (
                        <div className="no-data"> NO DATA !</div>
                      ) : (
                        filteredNews.map((key, i) => (
                          <div className="news" key={i}>
                            <Link
                              to={`/news/${
                                data.news_count.all_companies[key]
                                  .stock_symbol || key
                              }`}
                            >
                              <h4 className="ticker">
                                {data.news_count.all_companies[key]
                                  .stock_symbol || key}
                              </h4>
                            </Link>
                            <div className="count-details">
                              News-
                              <span style={{ color: "green", fontWeight: 700 }}>
                                {data.news_count.all_companies[key].news_count}{" "}
                              </span>
                              | Weekly Avg -{" "}
                              {
                                data.news_count.all_companies[key]
                                  .avg_7days_news
                              }
                            </div>
                          </div>
                        ))
                      );
                    })()
                  ) : (
                    <div className="no-data">No data available</div>
                  )}
                </div>
              </div>
              </div>
            </div>

            {/* last column complete starts */}
            
           
          </div>
        </div>
        {/* Footer disclaimer */}
        <div className="footer-disclaimer">
          <div className="disclaimer">
            *values are the highest/lowest values in last 15 days. In New Value
            column, “<span style={{ color: "red" }}>Red</span>” color signifies
            lowest where as “<span style={{ color: "green" }}>Green</span>”
            stands for highest
          </div>
          {/* updated */}
          <div className="updated">Updated on : {data.date}</div>
        </div>
      </section>

      <MiniFooter />
    </>
  );
};

// const RedDownArrow = () => (
//   <svg
//     style={{
//       height: "1rem",
//       aspectRatio: "1/1",
//       marginLeft: "0.5rem",
//     }}
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 15 18"
//     fill="#ED0000"
//   >
//     <path
//       d="M7.57637 17.2019L0.583213 0.417997L14.5695 0.417996L7.57637 17.2019Z"
//       fill="#ED0000"
//     />
//   </svg>
// );

// const GreenUpArrow = () => (
//   <svg
//     style={{
//       height: "1rem",
//       aspectRatio: "1/1",
//       marginLeft: "0.5rem",
//     }}
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 15 18"
//     fill="#2C8745"
//   >
//     <path
//       d="M7.57598 0.908096L14.5691 17.692L0.582821 17.692L7.57598 0.908096Z"
//       fill="#2C8745"
//     />
//   </svg>
// );

export default Trending;
